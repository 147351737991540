// =============================================================================
// Imports
// =============================================================================
import debounce from "lodash.debounce";

// =============================================================================
// Definition
// =============================================================================
let instance = null;

class Resize {

	// Constructor
	// -------------------------------------------------------------------------
	constructor() {
		if (!instance) {
			instance = this;
		}

		this.init();
		this.initEvents();

		return instance;
	}

	// Inits
	// -------------------------------------------------------------------------
	init() {
		this.methods = [];
	}

	initEvents() {
		window.addEventListener("resize", debounce(() => this.handleResize(), 100));
	}

	// Handlers
	// -------------------------------------------------------------------------
	handleResize() {
		this.methods.forEach((method) => {
			const isFunction = typeof method === "function";

			if (method && isFunction) {
				method();
			}
		});
	}

	add({
		method = () => null,
	} = {}) {
		if (typeof method !== "function") {
			return;
		}

		this.methods.push(method);
	}

	// Life cycle
	// -------------------------------------------------------------------------
	destroy() {
		this.methods = [];

		window.removeEventListener("resize", debounce(() => this.handleResize(), 100));
	}
}

export default new Resize();
